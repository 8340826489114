import { Dispatch } from 'redux';
import { HrpsApiClient } from '../../clients';
import { Logger } from '../../utils/logger';
import { ReturnOrderFilter, ReturnOrdersListOutput, TableSortDirection } from '../types';
import {
    RETURN_ORDER_LIST_CLEAR,
    RETURN_ORDER_LIST_DATE_RANGE_CHANGE,
    RETURN_ORDER_LIST_ERROR,
    RETURN_ORDER_LIST_GET,
    RETURN_ORDER_LIST_LOAD_MORE_DATA,
    RETURN_ORDER_LIST_LOADED,
    RETURN_ORDER_LIST_PAGE_CHANGE,
    RETURN_ORDER_LIST_QUERY_CHANGE,
    RETURN_ORDER_LIST_SORT_CHANGE,
    ReturnOrderListActions,
} from './returnOrderListActions';

export function getReturnOrderListLoading(): ReturnOrderListActions {
    return {
        type: RETURN_ORDER_LIST_GET,
        payload: {
            status: 'Loading',
            error: undefined,
            value: undefined,
        },
    };
}

export function getReturnOrderListLoaded(returnOrder: ReturnOrdersListOutput): ReturnOrderListActions {
    return {
        type: RETURN_ORDER_LIST_LOADED,
        payload: {
            totalResults: returnOrder.totalResults,
            returnOrdersList: returnOrder.returnOrdersList,
        },
    };
}

export function getReturnOrderListFailed(error: any): ReturnOrderListActions {
    return {
        type: RETURN_ORDER_LIST_ERROR,
        payload: {
            status: 'Failed',
            error,
            value: undefined,
        },
    };
}

export function returnOrderChangePage(page: number, newStart: number): ReturnOrderListActions {
    return {
        type: RETURN_ORDER_LIST_PAGE_CHANGE,
        payload: {
            currentPage: page,
            currentRecordNumber: newStart,
        },
    };
}

export function returnOrderUpdateQuery(query: string): ReturnOrderListActions {
    return {
        type: RETURN_ORDER_LIST_QUERY_CHANGE,
        payload: {
            query,
        },
    };
}

export function returnOrderUpdateSortChange(
    sortColumn: string,
    sortDirection: TableSortDirection
): ReturnOrderListActions {
    return {
        type: RETURN_ORDER_LIST_SORT_CHANGE,
        payload: {
            sortColumn,
            sortDirection,
        },
    };
}

export function returnOrderUpdateDateRange(dateRange: string[]): ReturnOrderListActions {
    return {
        type: RETURN_ORDER_LIST_DATE_RANGE_CHANGE,
        payload: {
            dateRange,
        },
    };
}

export function returnOrderLoadMoreData(currentPage: number, currentRecordNumber: number): ReturnOrderListActions {
    return {
        type: RETURN_ORDER_LIST_LOAD_MORE_DATA,
        payload: {
            currentPage,
            currentRecordNumber,
        },
    };
}

export function clearReturnOrderList(): ReturnOrderListActions {
    return {
        type: RETURN_ORDER_LIST_CLEAR,
        payload: {
            status: 'Uninitialized',
            error: undefined,
            value: undefined,
        },
    };
}

export function getReturnOrderList(
    resellerId: string,
    startAt: number,
    maxResults: number,
    sortColumn: string,
    sortDirection: 'ascending' | 'descending',
    dateRange?: string[],
    query?: string
) {
    return (dispatch: Dispatch) => {
        const hrpsApiClient = new HrpsApiClient();
        dispatch(getReturnOrderListLoading());
        return hrpsApiClient
            .getReturnOrders(
                resellerId,
                startAt,
                maxResults,
                buildSortColumn(sortColumn, sortDirection),
                buildSearchFilter(dateRange, query)
            )
            .then((returnOrderData: ReturnOrdersListOutput) => {
                dispatch(getReturnOrderListLoaded(returnOrderData));
            })
            .catch((err) => {
                dispatch(getReturnOrderListFailed(err));
            });
    };
}

export function getReturnOrderData(
    resellerId: string,
    startAt: number,
    maxResults: number,
    sortColumn: string,
    sortDirection: 'ascending' | 'descending',
    dateRange?: string[],
    query?: string
) {
    const hrpsApiClient = new HrpsApiClient();
    return hrpsApiClient.getReturnOrders(
        resellerId,
        startAt,
        maxResults,
        buildSortColumn(sortColumn, sortDirection),
        buildSearchFilter(dateRange, query)
    );
}

function buildSortColumn(sortColumn: string, sortDirection: 'ascending' | 'descending') {
    Logger.debug(
        '{returnOrderListActionCreators}',
        `In buildSortColumn SortColumn: ${sortColumn} sortDirection: ${sortDirection}`
    );
    switch (sortColumn) {
        case 'referenceId':
            return {
                REFERENCE_ID: sortDirection === 'ascending' ? 'ASCENDING' : 'DESCENDING',
                CREATION_DATE: 'DESCENDING',
            };
        case 'status':
            return {
                STATUS: sortDirection === 'ascending' ? 'ASCENDING' : 'DESCENDING',
            };
        case 'shippingAddress':
            return {
                SHIPPING_ADDRESS: sortDirection === 'ascending' ? 'ASCENDING' : 'DESCENDING',
            };
        case 'createdByUser':
            return {
                CREATED_BY_USER: sortDirection === 'ascending' ? 'ASCENDING' : 'DESCENDING',
            };
        case 'creationDate':
        default:
            return {
                CREATION_DATE: sortDirection === 'ascending' ? 'ASCENDING' : 'DESCENDING',
            };
    }
}

function buildSearchFilter(dateRange?: string[], query?: string): ReturnOrderFilter {
    const startDate: number | undefined =
        dateRange && dateRange[0] ? new Date(dateRange[0]).getTime() / 1000 : undefined;
    const endDate: number | undefined = dateRange && dateRange[1] ? new Date(dateRange[1]).getTime() / 1000 : undefined;
    const filter: ReturnOrderFilter = {
        searchValue: query || query !== '' ? query : undefined,
        creationDateStart: startDate,
        creationDateEnd: endDate,
    };
    return filter;
}

import Loader from '@amzn/meridian/loader';
import React, { FC, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { ColumnLayout, TableData } from '../../redux/types';
import { Button, useTranslation } from '../blocks';
import { Download } from '../icons';

function extractString(elem: React.ReactElement | string): string {
    if (!elem) {
        return '';
    }
    if (typeof elem === 'string') {
        return elem;
    }
    const { props } = elem;
    if (props != null) {
        if (props.text) {
            return props.text;
        } else if (props.status) {
            return props.status;
        } else if (props.translatedText) {
            return props.translatedText;
        }
        const { children } = props;
        if (children instanceof Array) {
            return children.map(extractString).join('');
        }
        return extractString(children);
    }
    return elem.toString();
}

function getTableText(data: TableData<any, keyof any>): string[][] {
    return data.mapRows((row) => {
        return row.map((field) => {
            return extractString(field);
        });
    });
}

function getHeaderText(headers: ColumnLayout<any, keyof any>[]): string[] {
    return headers.map((element) => {
        if (element.title) {
            return element.title.toString();
        } else {
            return element.toString();
        }
    });
}

const DownloadCsv: FC<DownloadCsvProps> = ({ getAllData, dataName }) => {
    const { t } = useTranslation('common');
    const [csvData, setCsvData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

    useEffect(() => {
        if (csvData && csvLinkRef.current) {
            csvLinkRef.current.link.click();
            setCsvData(null);
            setIsLoading(false);
        }
    }, [csvData]);

    async function download() {
        setIsLoading(true);
        const allData = await getAllData();
        setCsvData({
            data: getTableText(allData),
            headers: getHeaderText(allData.getHeaders()),
        });
        setIsLoading(false);
    }

    return (
        <>
            {csvData && (
                <CSVLink
                    data={csvData.data}
                    headers={csvData.headers}
                    filename={`${dataName}.csv`}
                    style={{ display: 'none' }}
                    ref={csvLinkRef}
                />
            )}
            <Button onClick={download}>
                {isLoading ? <Loader size={'small'} /> : <Download />}
                <p>{t('export-label')}</p>
            </Button>
        </>
    );
};

export type DownloadCsvProps = {
    getAllData: () => Promise<TableData<any, keyof any>>;
    dataName: string;
};

export default DownloadCsv;

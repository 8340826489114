import {
    getClaimUploadTemplates,
    requestClaimUploadUrl,
    processClaimUpload,
    processPdfClaimUpload,
    getClaimDocumentUrl,
} from './HrpsClaimsApi';
import { calculateContractTermsCompliance } from './HrpsContractTermsApi';
import { addCorrespondence, createIssue, getIssues } from './HrpsIssuesApi';
import { addLocation, editLocation } from './HrpsLocationsApi';
import { getResellerReturnTimeWindows } from './HrpsResellerApi';
import {
    createReturnOrderDraft,
    deleteReturnOrderDraft,
    getReturnOrderDocuments,
    submitReturnOrder,
    getReturnOrderProblemShelf,
    updateReturnOrderDraft,
    requestAttachmentUploadUrl,
    requestReturnOrderUploadUrl,
    processReturnOrderUpload,
    getReturnOrderUploadTemplates,
    deleteAttachments,
    getCreditMemoContent,
} from './HrpsReturnOrdersApi';
import {
    addUserTermsAndConditionsAcceptanceApi,
    getUserTermsAndConditionsAcceptancesApi,
} from './HrpsUserTermsAndConditionsApi';

export const useHrpsApi = () => {
    return {
        returnOrders: {
            getReturnOrderDocuments,
            createReturnOrderDraft,
            deleteReturnOrderDraft,
            submitReturnOrder,
            getReturnOrderProblemShelf,
            updateReturnOrderDraft,
            requestReturnOrderUploadUrl,
            processReturnOrderUpload,
            getReturnOrderUploadTemplates,
            requestAttachmentUploadUrl,
            deleteAttachments,
            getCreditMemoContent,
        },
        claims: {
            getClaimDocumentUrl,
            getClaimUploadTemplates,
            processClaimUpload,
            processPdfClaimUpload,
            requestClaimUploadUrl,
        },
        contractTerms: {
            calculateContractTermsCompliance,
        },
        locations: {
            addLocation,
            editLocation,
        },
        issues: {
            getIssues,
            createIssue,
            addCorrespondence,
        },
        reseller: {
            getResellerReturnTimeWindows,
        },
        termsAndConditions: {
            addUserTermsAndConditionsAcceptanceApi,
            getUserTermsAndConditionsAcceptancesApi,
        },
    };
};

import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from '../blocks';

const Svg = styled.svg`
    fill: none;
    width: 16px;
    height: 16px;
`;

export const Download = () => {
    const { t } = useTranslation('common');
    return (
        <Svg width={'16px'} height={'16px'} aria-label={t('icon-statusWaringLabel')}>
            <g id={'icon'}>
                <polyline
                    stroke={'currentColor'}
                    strokeWidth={'2'}
                    fill={'none'}
                    strokeLinecap={'round'}
                    strokeLinejoin={'round'}
                    points={'11 2 14 2 14 14 2 14 2 2 5 2'}
                />
                <polyline
                    stroke={'currentColor'}
                    strokeWidth={'2'}
                    fill={'none'}
                    strokeLinecap={'round'}
                    strokeLinejoin={'round'}
                    points={'4 6 8 10 12 6'}
                />
                <line
                    stroke={'currentColor'}
                    strokeWidth={'2'}
                    fill={'none'}
                    strokeLinecap={'round'}
                    x1={'8'}
                    y1={'1'}
                    x2={'8'}
                    y2={'10'}
                />
            </g>
        </Svg>
    );
};

/* eslint-disable max-lines-per-function */
import Box from '@amzn/meridian/box';
import DateRangePicker from '@amzn/meridian/date-range-picker';

import Heading from '@amzn/meridian/heading';
import Loader from '@amzn/meridian/loader';
import Row from '@amzn/meridian/row';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { BorderedColumn, GreyBox, WaitFor } from '../components/blocks';
import { Link, SearchField, Text, useTranslation } from '../components/blocks/localization';
import { ReturnOrderStatusIcon, TruncatedText } from '../components/composites';
import { PaginatedTable } from '../components/constructed';
import { ALL_RESULTS_NUM, MAX_RESULTS, RETURN_ORDERS_PER_PAGE } from '../constants';
import { getResellerDetails } from '../redux/actions/resellerActionCreators';
import {
    getReturnOrderData,
    getReturnOrderList,
    returnOrderChangePage,
    returnOrderLoadMoreData,
    returnOrderUpdateDateRange,
    returnOrderUpdateQuery,
    returnOrderUpdateSortChange,
} from '../redux/actions/returnOrderListActionCreators';
import { ReturnOrderListReturnOrder, RootState, TableData, TableSortDirection } from '../redux/types';
import { SiteMapPage } from '../types';
import { Logger } from '../utils/logger';
import { useSiteMapRouter } from '../utils/SiteMapRouter';

type SortData = {
    sortColumn: string;
    sortDirection: string;
};

const ReturnOrdersListPage: FC<ReturnOrdersListPageProps> = ({
    reseller,
    resellerDetails,
    returnOrderListState,
    getReturnOrderList,
    getResellerDetails,
    returnOrderChangePage,
    returnOrderLoadMoreData,
    returnOrderUpdateDateRange,
    returnOrderUpdateQuery,
    returnOrderUpdateSortChange,
}: ReturnOrdersListPageProps) => {
    const [query, setQuery] = useState<string>('');
    const { t } = useTranslation('returnOrdersListPage');

    const { getPath } = useSiteMapRouter();

    useEffect(
        () => {
            if (returnOrderListState.returnOrderData.status === 'Uninitialized') {
                if (reseller.value) {
                    getReturnOrderList(
                        reseller.value.resellerId,
                        returnOrderListState.startAt,
                        MAX_RESULTS,
                        returnOrderListState.sortColumn,
                        returnOrderListState.sortDirection,
                        returnOrderListState.dateRange,
                        returnOrderListState.query
                    );
                }
            }
            if (resellerDetails.status === 'Uninitialized' && reseller.value) {
                getResellerDetails(reseller.value.resellerId);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [returnOrderListState]
    );

    const submitQuery = useCallback(
        (queryValue: string) => {
            returnOrderUpdateQuery(queryValue);
        },
        [returnOrderUpdateQuery]
    );

    const onSort = useCallback(
        (data: SortData) => {
            const { sortColumn, sortDirection } = data;
            returnOrderUpdateSortChange(
                sortColumn,
                TableSortDirection[sortDirection as keyof typeof TableSortDirection]
            );
        },
        [returnOrderUpdateSortChange]
    );

    function setDateRange(dateRange: string[]) {
        returnOrderUpdateDateRange(dateRange);
    }

    const onPageChange = (value: string) => {
        const intValue = parseInt(value);
        if (intValue) {
            const calcNewStartRecord = RETURN_ORDERS_PER_PAGE * (intValue - 1);
            if (
                calcNewStartRecord < returnOrderListState.startAt + MAX_RESULTS &&
                calcNewStartRecord >= returnOrderListState.startAt
            ) {
                returnOrderChangePage(intValue, calcNewStartRecord);
            } else {
                returnOrderLoadMoreData(intValue, calcNewStartRecord);
            }
        }
    };

    const getAllData = async (): Promise<TableData<ReturnOrderListReturnOrder, 'returnOrderId' | 'createdByUser'>> => {
        if (reseller.value) {
            const data = await getReturnOrderData(
                reseller.value.resellerId,
                returnOrderListState.startAt,
                ALL_RESULTS_NUM,
                returnOrderListState.sortColumn,
                returnOrderListState.sortDirection,
                returnOrderListState.dateRange,
                returnOrderListState.query
            );
            return getTableData(data.returnOrdersList);
        }
        const returnOrderData = [] as ReturnOrderListReturnOrder[];
        return getTableData(returnOrderData);
    };

    const calculateNumberOfPages = (numOfReturnOrders: number | undefined) => {
        if (numOfReturnOrders) {
            return Math.ceil(numOfReturnOrders / RETURN_ORDERS_PER_PAGE);
        }
        return 0;
    };

    const formatDate = (dateNumber: number) => {
        const date = new Date(dateNumber * 1000);
        return date;
    };

    const formatAddress = (addressCode: string) => {
        const address = resellerDetails.value?.resellerLocations?.locations?.find(
            (location) => location.code === addressCode
        )?.shippingAddress;
        let addressString: string = '';
        if (address) {
            Logger.debug(
                'ReturnOrdersList',
                `Address: ${address.streetAddress.map((addressLine) => `${addressLine}\n`)} city: ${
                    address.city
                } zipcode: ${address.zipCode} state: ${address.state}`
            );
            const streetAddress = address.streetAddress.map((addressLine) => `${addressLine} `);
            addressString = `${streetAddress}${address.city}, ${address.state} ${address.zipCode}`;
            if (address.country) {
                addressString = `${addressString}, ${address.country}`;
            }
            return <TruncatedText text={t.cognate(addressString)} maxWidth={35} />;
        } else {
            return (
                <Row>
                    <Text>{t.cognate(addressCode)}</Text>
                    {resellerDetails.status === 'Failed' && <Text>{t('returnOrderList-addressInfoUnavailable')}</Text>}
                    {resellerDetails.status === 'Loading' && <Loader size={'small'} />}
                </Row>
            );
        }
    };

    const formatData = (returnOrdersList: ReturnOrderListReturnOrder[]) => {
        const startPos = (returnOrderListState.currentPage - 1) * RETURN_ORDERS_PER_PAGE - returnOrderListState.startAt;
        const endPos = returnOrderListState.currentPage * RETURN_ORDERS_PER_PAGE - returnOrderListState.startAt;

        const pageReturnOrdersList: ReturnOrderListReturnOrder[] = returnOrdersList.slice(startPos, endPos);

        return getTableData(pageReturnOrdersList);
    };

    const getTableData = (returnOrdersList: ReturnOrderListReturnOrder[]) => {
        const tableData = new TableData(returnOrdersList, [
            {
                title: t('referenceId-columnTitle'),
                uniqueId: 'referenceId',
                format: (returnOrder: ReturnOrderListReturnOrder) => (
                    <Link
                        type={'primary'}
                        href={
                            returnOrder.returnOrderStatus === 'DRAFTED'
                                ? getPath(SiteMapPage.updateDraftReturnOrder, {
                                      returnOrderId: returnOrder.returnOrderId,
                                  })
                                : getPath(SiteMapPage.viewReturnOrder, { returnOrderId: returnOrder.returnOrderId })
                        }
                        key={`${returnOrder.referenceId} - ${returnOrder.returnOrderId} - Link`}
                    >
                        {t.cognate(returnOrder.referenceId)}
                    </Link>
                ),
            },
            {
                title: t('returnOrderId-columnTitle'),
                uniqueId: 'returnOrderId',
                format: 'returnOrderId',
            },
            {
                title: t('creationDate-columnTitle'),
                uniqueId: 'creationDate',
                format: (returnOrder: ReturnOrderListReturnOrder) => (
                    <Text key={`${returnOrder.referenceId} - ${returnOrder.returnOrderId} - creationDateText`}>
                        {t('creationDate-columnValue', { dateTime: formatDate(returnOrder.creationDate) })}
                    </Text>
                ),
            },
            {
                title: t('status-columnTitle'),
                uniqueId: 'status',
                format: (returnOrder: ReturnOrderListReturnOrder) => (
                    <ReturnOrderStatusIcon
                        status={returnOrder.returnOrderStatus}
                        key={`${returnOrder.referenceId} - ${returnOrder.returnOrderId} - ReturnOrderStatusIcon`}
                    />
                ),
            },
            {
                title: t('shippingAddress-columnTitle'),
                uniqueId: 'shippingAddress',
                format: (returnOrder: ReturnOrderListReturnOrder) => formatAddress(returnOrder.shippingAddress),
            },
            {
                title: t('createdByUser-columnTitle'),
                uniqueId: 'createdByUser',
                format: 'createdByUser',
            },
        ]);
        return tableData;
    };

    Logger.debug('{ReturnOrdersList}', `Total Records Found are: ${returnOrderListState.totalRecords}`);
    return (
        <BorderedColumn alignmentHorizontal={'center'} spacing={'none'}>
            <GreyBox type={'fill'} spacingInset={'small'} width={'100%'}>
                <Heading level={5} alignment={'left'} data-testid={'returnOrdersListPage.header'}>
                    {t('returnOrdersList-pageHeading', { count: returnOrderListState.totalRecords })}
                </Heading>
            </GreyBox>
            <WaitFor lazyValue={returnOrderListState.returnOrderData}>
                {(returnOrderData) => (
                    <PaginatedTable
                        data={formatData(returnOrderData)}
                        dataTestId={'returnOrdersListTable'}
                        currentPage={returnOrderListState.currentPage}
                        numberOfPages={calculateNumberOfPages(returnOrderListState.totalRecords)}
                        onPageChange={onPageChange}
                        onSort={onSort}
                        sortColumn={returnOrderListState.sortColumn}
                        sortDirection={returnOrderListState.sortDirection}
                        key={`PaginatedTable`}
                        rowKey={(rowArray: string[], rowObject: ReturnOrderListReturnOrder) => {
                            return rowObject.returnOrderId;
                        }}
                        getAllData={getAllData}
                        dataName={t('returnOrdersList-pageHeading', {
                            count: Math.min(returnOrderListState.totalRecords || 0, ALL_RESULTS_NUM),
                        })}
                    >
                        <Box
                            width={'80%'}
                            spacingInset={'none'}
                            data-testid={'returnOrdersListPage.filterCriteriaBox'}
                            key={'returnOrdersListPage.filterCriteriaBox'}
                        >
                            <Row spacing={'xlarge'} key={'returnOrdersListPage.filterCriteriaBox - Row'}>
                                <SearchField
                                    value={query}
                                    onChange={setQuery}
                                    label={t('findReturnOrders-searchPlaceholder')}
                                    onSubmit={submitQuery}
                                    searchButton={true}
                                    data-testid={'returnOrdersListPage.searchField'}
                                    key={'returnOrdersListPage.filterCriteriaBox - SearchField'}
                                />
                                <Row
                                    key={'returnOrdersListPage.filterCriteriaBox - Row'}
                                    className={'dateRangeContainer'}
                                >
                                    <DateRangePicker
                                        value={returnOrderListState.dateRange}
                                        startLabel={t('dateRangePicker-startPlaceholder')}
                                        endLabel={t('dateRangePicker-endPlaceHolder')}
                                        allowSameStartAndEnd={true}
                                        onChange={setDateRange}
                                        monthsInView={2}
                                        clearButton={true}
                                        size={'medium'}
                                        data-testid={'returnOrdersListPage.dateRangePicker'}
                                        key={'returnOrdersListPage.filterCriteriaBox - DateRangePicker'}
                                    />
                                </Row>
                            </Row>
                        </Box>
                    </PaginatedTable>
                )}
            </WaitFor>
        </BorderedColumn>
    );
};

const mapStateToProps = ({ resellerReducer, resellerReturnOrderListReducer }: RootState) => {
    return {
        reseller: resellerReducer.selectedReseller,
        resellerDetails: resellerReducer.resellerDetails,
        returnOrderListState: resellerReturnOrderListReducer,
    };
};

const mapDispatchToProps = {
    getReturnOrderList,
    returnOrderChangePage,
    returnOrderLoadMoreData,
    returnOrderUpdateDateRange,
    returnOrderUpdateQuery,
    returnOrderUpdateSortChange,
    getResellerDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReturnOrdersListPageProps = ConnectedProps<typeof connector>;
export default connector(ReturnOrdersListPage);

import { IAuthorizedReseller, TableData, TableDataReseller } from '../redux/types';

export const BLANK_RESELLERS: IAuthorizedReseller[] = [
    {
        countryCode: 'US',
        name: '',
        resellerId: '',
        status: 'active',
    },
] as IAuthorizedReseller[];

export const ALL_RESELLERS: IAuthorizedReseller = {
    countryCode: '*',
    name: '** ALL Reseller **',
    resellerId: '*',
    status: 'active',
} as IAuthorizedReseller;

export const TEST_RESELLERS: IAuthorizedReseller[] = [
    {
        countryCode: 'US',
        name: 'Reseller 1',
        resellerId: 'bestBuy-1',
        status: 'active',
    },
    {
        countryCode: 'US',
        name: 'Reseller 2',
        resellerId: 'bestBuy-us',
        status: 'active',
    },
    {
        countryCode: 'US',
        name: 'Reseller 3',
        resellerId: 'bestBuy-ca',
        status: 'active',
    },
    {
        countryCode: 'US',
        name: 'Reseller 4',
        resellerId: 'bestBuy-eu',
        status: 'active',
    },
] as IAuthorizedReseller[];

export const USERS_PER_PAGE = 13;

const resellers: TableDataReseller[] = [
    {
        reseller: 'Best Buy',
        store: 'Store #1234',
        email: 'sales@bestbuy.com',
        phone: '556-555-5456',
    },
    {
        reseller: 'Target',
        store: 'Store #1234',
        email: 'ops@target.com',
        phone: '425 435 8987',
    },
    {
        reseller: 'Hamtown',
        store: 'Ham #123',
        email: 'sales@hamtown.com',
        phone: '488 466 4565',
    },
    {
        reseller: 'Hamtown',
        store: 'Hamtown #14',
        email: 'sales14@hamtown.com',
        phone: '422 202 8987',
    },
];

export const RESELLER_TABLE_DATA = new TableData(resellers, [
    { title: 'Reseller', uniqueId: 'reseller', format: 'reseller' },
    { title: 'Store', uniqueId: 'store', format: 'store' },
    { title: 'Contact Email', uniqueId: 'email', format: 'email' },
    { title: 'Contact Phone', uniqueId: 'phone', format: 'phone' },
]);

export const RETURN_ORDERS_PER_PAGE = 13;
export const MAX_RESULTS = RETURN_ORDERS_PER_PAGE * 10;
export const CLAIMS_PER_PAGE = 20;
export const MAX_CLAIM_RESULTS = 20;
export const DEFAULT_DATE_RANGE_DAYS: number = 365;
export const ALL_RESULTS_NUM = 10000;
